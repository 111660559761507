<template>
	<div class="community">
		<div class="itemList" v-for="item of TableData" @click="ViewArticle(item)">
			<div class="itemImg" >
				<img :src="item.image"  />
				<img :src="item.smlimage" v-if="item.smlimage" class="headimg"/>
			</div>
			<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
				<img src="../../../public/static/images/zan.png" height="25" width="25">
				<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
			</div>
			<div class="itemName">{{item.title}}</div>
			<div class="itemVolume">{{item.summary}}</div>
			<div class="imgs" v-if="!item.smlimage">
				<img src="../../../public/static/images/zan.png" height="25" width="25">
				<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
			</div>
		</div>
		
		<div class="itemList" v-if="TableData.length==0">
			<div style="text-align: center; padding: 50px;">还没有任何内容</div>
		</div>
		
		
		<div style="height: 20px;width: 100%;"></div>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				pagination: {
					pageidx: 1,
					dataover: false
				},
				typeid:0,
				subtype:0,
				TableData:[]
			}
		},
		
		beforeMount() {
			let typeid = this.$route.params.id;
			if(typeid.indexOf('_')>0){
				this.subtype = typeid.split('_')[1]
				this.typeid = typeid.split('_')[0]
			}
			if(this.typeid){
				this.GetArticleList();
				
				if(this.typeid==1){
					document.title="微课堂";
				}else if(this.typeid==2){
					document.title="咨询";
				}else if(this.typeid==3){
					document.title="直播";
				}
			}
		},
		components: {

		},

		mounted() {
		

		},
		methods: {

			GetArticleList() {
				
				this.$store.dispatch("GET", {
					url: "/api/article/?action=list",
					query: {
						type: this.typeid,
						subtype:this.subtype,
						pageidx: this.pagination.pageidx,
						rows:1000
					}
				}).then(result => {
					if(result.data) {

						this.TableData = result.data.rows.rows

					} 
				})
			},
			ViewArticle(item) {
				if(item.link.indexOf("http")>=0){
					window.location.href=item.link;
				}else{
					if(isNaN(item.link)){//商品id
						this.$router.push("/prodetail/" + item.link)
					}else{
						this.$router.push("/article/" + item.id)
					}
				}
				
			},
	
	
			
		}
	}
</script>

<style scoped="scoped">
	.community{
		height: 100%;
		width: 100%;

	}
	.itemList{
	    background: #FFFFFF;
	    border-radius: 5px;
	    
	    width: 90%;
	    margin: 0 auto;
	    margin-top: 20px;
	    padding-bottom: 10px;
	}
	.itemImg{
		width: 100%;
		height: 54vw;
		position: relative;
	}
	.headimg{ position: absolute; width: 20vw; height: 20vw; left: 20px; bottom: -10vw; border-radius: 50%;}
	.itemImg img:nth-child(1){
		height: 100%;
		width: 100%;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.itemName{
		font-size: 16px;
		color: #474747;
		padding: 12px;
	}
	.itemVolume{
		font-size: 12px;
		color: #9a9a9a;
		padding:5px 12px;
	}
	.imgs{
		padding:5px 12px 15px 12px;
		text-align: right;
	}
</style>